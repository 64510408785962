.wrapper{
    background-color: rgb(22, 35, 80);
    height: 95vh;
}

.mainDivs{
    height: 90vh;
}

#leftDiv{
    background-color: rgb(255, 255, 255);
}

#rightDiv{
    background-color: darkgoldenrod;
}

#thirdDiv{
    background-color: rgb(258, 235, 255);
}

#fourthDiv{
    background-color: #a1451e;
    text-align: right;
}

.mainTitle{
 margin-top: 40%;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 80px;
line-height: 93px;
/* identical to box height */

letter-spacing: 0.3em;

color: #000000;

}


.navHome{
margin-top: 5%;

    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 90px;
line-height: 104px;
text-align: right;
letter-spacing: 0.05em;

color: #000000;

}
